import styled from '@emotion/styled'
import { Button } from '@rebass/emotion'

const PrimaryButton = styled(Button)`
  border: 1px solid #133235;
  color: #133235;
  background-color: #ffc000;
  font-family: ${props => props.theme.fonts.oswald};
  cursor: pointer;
  letter-spacing: 1px;

  :disabled {
    opacity: 0.9;
  }
`

PrimaryButton.defaultProps = {
  fontSize: [22],
  px: [30],
  py: [10],
  mb: [30, 0],
  as: 'button',
  borderRadius: [5]
}

const SecondaryButton = styled(Button)`
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #12324b;
  font-family: ${props => props.theme.fonts.oswald};
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;

  :hover {
    background-color: #12324b;
    color: #fff;
  }
`

SecondaryButton.defaultProps = {
  fontSize: [20],
  px: [30],
  py: [10],
  as: 'a'
}

const RoundButton = styled(Button)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #000;
  background-color: #fff;
  font-family: ${props => props.theme.fonts.oswald};
  cursor: pointer;
  display: block;
  text-decoration: none;
  letter-spacing: -1px;
  lineheight: 30px;
  text-align: center;
  outline: none;

  :hover {
    background-color: ${props => props.theme.colors.penguin};
    color: #fff;
  }
`

RoundButton.defaultProps = {
  fontSize: [30],
  px: [0],
  py: [0],
  as: 'button'
}


const CloseButton = styled(RoundButton)`
  transform: rotate(45deg);
  margin-top:0px;
  margin-right: 0px;
  margin-left: auto;
  border-width: 0px;
`

CloseButton.defaultProps = {
  fontSize: [30],
  px: [0],
  py: [0],
  as: 'button'
}

const LinkButton = styled(Button)`
  border: none;
  color: ${props => props.theme.colors.gorilla};
  font-family: ${props => props.theme.fonts.oswald};
  cursor: pointer;

  :hover {
    color: #333;
  }
`

LinkButton.defaultProps = {
  fontSize: [16],
  as: 'button'
}

export { PrimaryButton, SecondaryButton, RoundButton, CloseButton, LinkButton }
