import styled from '@emotion/styled'
import { Flex } from '@rebass/emotion'

const FlexContainer = styled(Flex)()

FlexContainer.defaultProps = {
  maxWidth: [1440],
  width: ['100%'],
  m: 'auto'
}

export default FlexContainer
